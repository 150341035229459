import React from 'react';
import { Menu, Container } from 'semantic-ui-react';

function NavBar() {
  return (
    <Menu>
      <Container text>
        <Menu.Item>
          <i className='phone icon'></i>
        </Menu.Item>
        <Menu.Item header>we·x</Menu.Item>
        <Menu.Item position='right'>
          <img
            src='https://we-x.io/wp-content/uploads/2024/01/logo-wex.png'
            avatar
          />
        </Menu.Item>
        <Menu.Item>v.2305.2116</Menu.Item>
      </Container>
    </Menu>
  );
}

export default NavBar;
