import React, { useEffect, useState } from 'react';
import Login from './components/Login';
import { useImmer } from 'use-immer';
import axios from './utils/Axios';
import socket from './utils/SocketIo';
import useLocalStorage from './hooks/useLocalStorage';
import CallCenter from './components/CallCenter';
import Out from './components/out';
import Outbound from './components/outbound';
import CallAnswerRedirect from './components/callAnswerRedirect';
import NavBar from './components/NavBar';
import audioFile from '../src/sounds/wait.mp3';
// recibir parámetros de gina
import { useLocation } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {processEndPointUser} from '../src/utils/gina.js';
import * as Pbx from 'twilio-client';
import { haciaGinaNewCall , haciaGinaNewCallTransfer ,haciaGinaEndCallOut, haciaGinaEndCall , haciaGinaEndCallTransfer , haciaGinaEndCallOutound, getURI} from './utils/gina';
import { Socket } from 'socket.io-client';
// Por ejemplo, en tu componente o archivo de configuración
let IDCASO;
let IDLLAMADA;

// funcion para obtener el querystring
function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split('=');
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}
let i = 1
// funcion para enviar al webservices de gina


function App() {




  //new Call-  inbound
  const [newCall,setIsNewCallActive] = useState(false);
  const [endCall ,setEndCallInbound] = useState(false);
  const [From,setFrom] = useState();
  const [callStatus , setCallStatus] = useState();
  const [callSid, setCallSid] = useState(); // id llamada nueva
  const [To, setTo] = useState();
  const [queue, setQueue] = useState();

  const [idCasoTransfer , setIdCasoTransfer] = useState();
  const [storedToken, setStoredToken] = useLocalStorage('wexToken', null);
  const [pbxToken, setPbxToken] = useState();
  const [device,setDevice] = useState();
//`
  const [socketAgenteOutbound , setSocketAgentOutbound] = useState();
  const [socketAgentCallOutAuto,setSocketAgentOutboundAuto] = useState();
  const [hangupAgent, setHangUpAgent] = useState(true);


//transfer
 
  const [callSidTransfer, setCallSidTransfer] = useState();
  const [isCallActive, setIsCallActive] = useState(false);
  const [skill, setSkillDestino] = useState(); // gaurda el queue que nos manda gina
  const [callTransfer , setCallTransfer] = useState();
  const  [endCallTransfer,setEndCallTransfer] = useState(false);
///
  const [numberClient, setClientNumber] = useState();
  const [compainNumber, setNumberCompain] = useState();
  const [idCaseCompain, setIdCaso] = useState();
  const [idAgenteCompain, setIdAgente] = useState();
  const [sidTransfer, setIdTransfer] = useState();
  const [respaldo,setRespaldo] = useState();
  const [out,setOut] = useState();
  const [endCallOutAuto,setEndcallOutAuto] = useState();
  const [outAuto, setOutAuto] = useState();
  const [endCallOut,setEndCallOut] = useState();
  const [callStatusOut,setCallStatusOut] = useState();
  const [typeCall,setTypeCall] = useState();
  // recibo idagente de la url de Gina
  const idAgente = getQueryVariable('idagente'); // comparar este id agente que sea igual al del bakend
  const queueAgente = getQueryVariable('queue');
  const typeAgent = getQueryVariable('tipo');
  const ToURI = getQueryVariable('To');
  const FromURI = getQueryVariable('From');
  const IDCASO = getQueryVariable('IDCASO');

  const endPoint  =  getQueryVariable('env');
  const [endPointUser, setEndPoint] = useState();
  const tel = numberClient;
  const caseCompain = idCaseCompain;
  //tipo in o out si es in contestar si es out boton llamar
  const [timer, setTimer] = useState(false); //audio
  const [connected , setConnected] = useState(false);
  async function postJSONnew(data) {
    try {
      const response = await fetch(
        endPointUser,
        {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );
  
      const result = await response.json();
      console.log('Success:', result.IDCASO);
      IDCASO = result.IDCASO;
      console.log('Global IDCASO:', IDCASO);
    } catch (error) {
      console.error('Error:', error);
    }
  }
  
  async function postJSONend(data) {
    try {
      const response = await fetch(
        endPointUser,
        {
          method: 'POST', // or 'PUT'
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(data),
        }
      );
  
      const result = await response.json();
      console.log('Success:', result);
      return result;
    } catch (error) {
      console.error('Error:', error);
    }
  }
  function validateNumber(tel) {
    if (typeof tel === 'string' && tel.trim() !== '') {
      return true;
    } else {
      // La variable 'tel' no es una cadena o está vacía
      return false;
    }
  }

  //console.log(idAgente, queueAgente);

  // recibo caso
  const [caso, setCaso] = useImmer({
    caso: '',
  });

  const [calls, setCalls] = useImmer({
    calls: [],
  });
  const [user, setUser] = useImmer({
    username: '',
    mobileNumber: '',
    verificationCode: '',
    verificationSent: false,
  });
  
  

  useEffect(() => {
    if (pbxToken) {
      console.log(`Token recibido del backend ` , pbxToken);
      connectPbxVoiceClient(pbxToken);
    }
  }, [pbxToken]);

  useEffect(() => {
    if (endPointUser) {
      //console.log(`ENDDPOINT ` , endPointUser);y
      processEndPointUser(endPointUser);
    }
  }, [endPointUser]);
  
  
  useEffect(() => {
    if (socketAgenteOutbound) {
       
    
    }
  }, [socketAgenteOutbound]);

  
  useEffect(() => {
    if (callStatus === 'completed') {
      console.log(`ANTES DE MANDAR A GINA END-CALL`);
      haciaGinaEndCall(idAgente,callSid,To,From,queue);
      //setIsNewCallActive(false);
      
    }else if(callStatus ===  'in-progress'){
      console.log(`ANTES DE MANDAR A GINA  llamada In PROGRESS END-CALL-Transfer`);
      //haciaGinaEndCallTransfer(idAgente,callSidTransfer,To,From,skill,idCasoTransfer);
      
    }
  }, [endCall]);

  //CALL-OUT-AUTO URL
  useEffect(() => {
    if (callStatus === 'completed') {
      console.log(`END-CALL-out-auto`);
      haciaGinaEndCallOutound(idAgente,callSid,ToURI,FromURI,queue,idCaseCompain);
      //setIsNewCallActive(false);
    }
  }, [endCallOutAuto]);
 

  useEffect(() => {
    if (callStatus === 'completed') {
      console.log(`ANTES DE MANDAR A GINA END-CALL-Transfer`);
      haciaGinaEndCallTransfer(idAgente,callSidTransfer,To,From,skill,idCasoTransfer);
      
      
    }
  }, [endCallTransfer]);

  useEffect(() => {
    if (callStatusOut === 'completed') {
      console.log(`ANTES DE MANDAR A GINA END-CALL-Out`);
      haciaGinaEndCallOut(idAgente,endCallOut,numberClient,compainNumber,skill,caseCompain);    
    }
  }, [endCallOut]);

  

  useEffect(() => {
    if (isCallActive && callTransfer == true) {
      console.log(`MANDANDOOO TRANSFER` , callSidTransfer);
      haciaGinaNewCallTransfer(idAgente,callSidTransfer,To,From,skill,idCasoTransfer);
    }
    
  }, [callSidTransfer]);
  
  useEffect(() => {
    if (FromURI && ToURI && i < 4 ) {
    
      axios.post(`/call-out`, {
          IDCASO: "",
          IDAGENTE: idAgente,
          SKILL: queueAgente,
          SKILLDESTINO: '',
          CLIENTNUMBER: ToURI,
          COMPANYNUMBER: FromURI
      });
      i++;
  }
    
  });
  
  
  useEffect(() => {
    if (newCall && callTransfer == false) {
      console.log(`Antes de mandar A GINAAAAAA` , idAgente,callSid,To,From,queue);
      haciaGinaNewCall(idAgente,callSid,To,From,queue);
    }
  }, [callSid]);

  useEffect(() => {
    if (callStatus === 'completed') {
      console.log(`Colgando LLamada OUTBOUNDD`);
    }
  }, [socketAgenteOutbound]);

  
useEffect(() => {
  socket.emit('endPointUser' , endPoint);
  }, [endPoint]);

  useEffect(() => {
    socket.on('connect', () => {
      console.log('Socket conectado');
      socket.emit('agente', idAgente, queueAgente, typeAgent);
      socket.emit('tokenPbx', idAgente);
    });
    socket.on('disconnect', () => {
      socket.emit('delete-database', idAgente); // Corregir el nombre del evento aquí
      console.log('Socket desconectado');
    });
    
    socket.on('assigned-EndPoint', (endPoint) => {
      setEndPoint(endPoint);  
      
    });
    //recibimos el token del backend
    socket.on('pbx-token', (data) => {
      //console.log('Recive token del backend', data.token);
      //console.log('token Recibido')
      setPbxToken(data.token);  
      
    });
    socket.on('call-transfer', (newCall, queue, socketId, callTransfer, IDCASO, respaldo) => {
      const { CallSid, CallStatus, To, From } = newCall;
      
      if (socket.id === socketId) {
        setCallSidTransfer(CallSid);
        setRespaldo(respaldo);
        console.log(`CALL SID TRANSFERRR`, respaldo);
        setSkillDestino(queue);
        setCallTransfer(true);
        setCallStatus(CallStatus);
        setIdCasoTransfer(IDCASO);
        setFrom(From);
        setTo(To);
        console.log(`TRANSFER PARA `, socketId);
        setIsCallActive(true); 
      }
    });

    socket.on('call-out', ({ data: { IDCASO, IDAGENTE, SKILL, CLIENTNUMBER, COMPANYNUMBER } }, socketAgentOutbound) => {
      setHangUpAgent(true);
      if (socket.id === socketAgentOutbound && idAgente === IDAGENTE && queueAgente === SKILL) {
        setSocketAgentOutbound(socketAgentOutbound);
        setCallStatusOut(callStatus);
        console.log(`El socket del Agente outbound ` , socketAgenteOutbound) ;
        setClientNumber(CLIENTNUMBER);
        setNumberCompain(COMPANYNUMBER);
        setIdCaso(IDCASO);
        setIdAgente(IDAGENTE);
        setSkillDestino(SKILL);
  
        console.log(`Socket Agente: `, socket.id, `Socket a asignar llamada : `, socketAgentOutbound);
        console.log(`llamada de salida asignada para agente Gina: `, IDAGENTE);
        setOut(true);
      }
    });
    socket.on('call-out-auto', ( socketAgentOutbound) => {
      setHangUpAgent(true);
      if (socket.id === socketAgentOutbound) { 
        setSocketAgentOutboundAuto(socketAgentOutbound);
        setClientNumber(ToURI);
        setNumberCompain(FromURI);
        setIdAgente(idAgente);
        setSkillDestino(queueAgente);
  
        
        setOutAuto(true);

      }
    });

    // evento al recibir nueva llamada desde el server, lo envía a todos los agentes
    socket.on(
      'call-new',
      (newCall, queue,socketId,callTransfer,IDCASO) => { // Desestructura el array sms 
        const { CallSid , CallStatus, To, From } = newCall; // propiedades dentro de newCall
        setCallSid(CallSid);
        setCallStatus(CallStatus);
        setCallTransfer(callTransfer);
        //console.log(`CALL NEWW  ` , queue,socketId,callTransfer,IDCASO )
        if (queue === queueAgente && socket.id === socketId  && callTransfer=== false ) {
              // aquí tienes que mandar la llamada al webservice de Gina
          console.log(`No es TRANSFERRRR`,From);
          setQueue(queue)
          setTo(To);
          setFrom(From);
          setIsNewCallActive(true);
          console.log('Socket call-new', CallSid);   

        }
        }
    );
    socket.on(
      'call-status-changed',
      ({ data: { CallSid, CallStatus, To, From } },socketId,queue,typeCall, transfer) => {
       // console.log(`COLGARRR callOutttt ` , numberClient , compainNumber, idCaseCompain, agentGinaWex, queueOutbound ) 
       // if (CallStatus === 'completed' && IDCASO != 0 && IDCASO != undefined) {
  
        if (socketId === socket.id && queue === queueAgente && typeCall === 'inbound' && CallStatus=== 'completed' && transfer === false) {
          setCallStatus(CallStatus);
          setIsNewCallActive(false);
          console.log(`Valor endCall de llamada entrante ` , endCall);
          setEndCallInbound(CallSid);


        }if (socketId === socket.id  && transfer) {
          setCallStatus(CallStatus);
          setIsCallActive(false);
          setEndCallTransfer(CallSid);
          
          console.log(`Transferencia Colgada`);
    
         
         
        }if(CallStatus === 'in-progress' && socket.id === socketId){
           setCallStatus(CallStatus);
           setIsNewCallActive(false);
           setEndCallInbound(CallSid);  
        }
        if(socket.id === socketId && typeCall === 'outbound-auto-api'){
          console.log(`COLGANDO OUT AUTO`)
          setCallSid(CallSid);
          setCallStatus(CallStatus);
          setOutAuto(false);
          setEndcallOutAuto(CallSid);
         
        }
         if (socketId === socket.id && typeCall === 'outbound-api') {
            setTypeCall(typeCall);
            setCallStatusOut(CallStatus);
            setEndCallOut(CallSid);
            console.log(`COlgando llamada de salidaaaaa OUT `);
            //haciaGinaEndCallOutound(idAgente,CallSid,To,From,queue,idCaseCompain)

        
           setOut(false);
           
          }
      }
    );
     
      


    socket.on('enqueue', ({ data: { CallSid } }, queue) => {
      setCalls((draft) => {
        const index = draft.calls.findIndex(
          ({ CallSid }) => CallSid === CallSid
        );
        if (index === -1) {
          return;
        }
        draft.calls[index].CallStatus = 'enqueue';
      });
    });
    return () => {};
  }, []);

  useEffect(() => {
    //comp timer
    socket.on('setTimer', (value,socketId) => {
      if (socket.id === socketId && value && socketId) {
        console.log(`Timer active app`);
        setTimer(value)
           
      } 
    });

    socket.on('connected', (connectedCall, socketId) => {
      if (connectedCall && socket.id=== socketId) {
        console.log(`Call Connected app`);
        setConnected(connectedCall);
       
      } 
    });  
   
     
   }, []);
  


  //redireccionamiento

  async function sendSmsCode() {
    console.log('Enviando SMS');
    await axios.post('/login', {
      to: user.mobileNumber,
      username: user.username,
      channel: 'sms',
    });
    setUser((draft) => {
      draft.verificationSent = true;
    });
  }

  async function sendVerificationCode() {
    console.log(
      'Enviando código de verificación:',
      user.mobileNumber,
      user.verificationCode
    );
    const response = await axios.post('/verify', {
      to: user.mobileNumber,
      code: user.verificationCode,
      username: user.username,
    });
    console.log('Token recibido', response.data.token);
    setStoredToken(response.data.token);
  }

  function connectPbxVoiceClient(pbxToken) {
    const devicePBX = new Pbx.Device(pbxToken, { debug: true });
    setDevice(devicePBX);
    devicePBX.on('error', (error) => {
      console.error('Error del dispositivo:', error);
    });

    devicePBX.on('incoming', (connection) => {
      console.log('Llamada entrante recibida');
      connection.accept();
    });
  }

 


  return (
    <div>
      {/* Agregar otras condiciones para mostrar componentes basados en otras variables */}
      {idAgenteCompain === idAgente && tel && out && hangupAgent  ? (
        <Out
          tel={tel}
          idAgente={idAgente}
          compainNumber={compainNumber}
          caseCompain={caseCompain}
          pbxToken = {pbxToken}
          device = {device}
          setHangUpAgent = {setHangUpAgent} 
          endPointUser={endPointUser}    
          queueAgente={queueAgente}
       /> 
      ) : (
        <div>
          {newCall ? (
            <CallCenter
              callSid={callSid}
              from = {From}
              callStatus={callStatus}
              idAgente={idAgente}
              device = {device}
            />
          ) :  (
            <div>
              {/* Add your new condition here */}
              {outAuto && idAgenteCompain === idAgente ? (
                <Outbound
                tel={tel}
                idAgente={idAgente}
                compainNumber={compainNumber}
                pbxToken = {pbxToken}
                device = {device}
                setHangUpAgent = {setHangUpAgent} 
                endPointUser={endPointUser}   
                queueAgente={queueAgente}
                IDCASO={IDCASO}
              

                />
              ) : (
                <NavBar />
              )}
            </div>
          )}
        </div>
      )}
      {/* Mostrar los botones de respuesta y colgar si isCallActive es true o skill es igual a queueAgente */}
      {isCallActive && skill === queueAgente &&  (
        <CallAnswerRedirect
          respaldo={respaldo}
          from ={From}
          callStatus= {callStatus}
          idAgente={idAgente}
          device ={device}
        />
      )}
    </div>
  );
}


export default App;